import React, { createContext, useContext, useState, useEffect, FC, ReactNode } from 'react';
import useSettings from 'src/hooks/useSettings';
import { Pos } from 'src/types/pos';

interface CurrencyContextProps {
  isEuropeanMoney: boolean;
  currencySymbol: string;
  setEuropeanMoney: (isEuropean: boolean) => void;
}

const CurrencyContext = createContext<CurrencyContextProps | undefined>(undefined);

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};

// Define props for the CurrencyProvider component
interface CurrencyProviderProps {
  children: ReactNode;
}

export const CurrencyProvider: FC<CurrencyProviderProps> = ({ children }) => {
  const [isEuropeanMoney, setEuropeanMoney] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const { settings } = useSettings();

  useEffect(() => {
    // Determine currency based on POS type
    if (settings.pos === Pos.SumUp) {
      setEuropeanMoney(true);
      setCurrencySymbol('£');
    } else {
      setEuropeanMoney(false);
      setCurrencySymbol('$');
    }

    // Alternatively, use the Geolocation API (commented out)
    /*
    const determineCurrencyBasedOnLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Use a geolocation service API or reverse geocoding to get the country code
            const response = await fetch(`https://geocode.xyz/${latitude},${longitude}?json=1`);
            const data = await response.json();
            const countryCode = data.country || data.prov;

            // List of European country codes to determine if the location is in Europe
            const europeanCountries = ['GB', 'FR', 'DE', 'IT', 'ES', 'NL', 'BE', 'SE', 'CH', ...];

            if (europeanCountries.includes(countryCode)) {
              setEuropeanMoney(true);
              setCurrencySymbol('£');
            } else {
              setEuropeanMoney(false);
              setCurrencySymbol('$');
            }
          } catch (error) {
            console.error('Error fetching location data', error);
            setEuropeanMoney(false);
            setCurrencySymbol('$');
          }
        }, (error) => {
          console.error('Error getting location', error);
          setEuropeanMoney(false);
          setCurrencySymbol('$');
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
        setEuropeanMoney(false);
        setCurrencySymbol('$');
      }
    };

    determineCurrencyBasedOnLocation();
    */
  }, [settings.pos]);

  return (
    <CurrencyContext.Provider value={{ isEuropeanMoney, currencySymbol, setEuropeanMoney }}>
      {children} 
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
