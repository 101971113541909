export enum Pos {
    None = 'None',
    Square = 'Square',
    Appetize = 'Appetize',
    Centarro = 'Centarro',
    RetailCloud = 'RetailCloud',
    Demo = 'Demo',
    RetailPro = 'RetailPro',
    NSPOS = 'NSPOS',
    SumUp = 'SumUp'
}